import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Masonry from 'react-masonry-css'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const DigitalsPageTemplate = ({
  photos
}) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 2,
    500: 1
  };
  return (
    <section className="section digitals">
      <Helmet title={`Itza | Digitals`} />
      <div className="container content">
        <div className="columns">
          <div className="column">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid pt-2"
              columnClassName="my-masonry-grid_column">
              {photos[0].node.frontmatter.Photoshoot.photos.map((photo, index) => (
                <PreviewCompatibleImage key={index} imageInfo={photo} />
              ))}
            </Masonry>
          </div>
        </div>
      </div>
    </section>
  );
};

DigitalsPageTemplate.propTypes = {
  photos: PropTypes.array,
};

const DigitalsPage = ({data}) => {
  const { edges: digis } = data.allMarkdownRemark;
  return ( 
    <Layout>
      <DigitalsPageTemplate
        photos={digis}
      /> 
    </Layout>
  );
};

DigitalsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    }),
  }),
};

export default DigitalsPage;

export const pageQuery = graphql`
query MyQueryDIGI {
  allMarkdownRemark(
    filter: {frontmatter: {Photoshoot: {}, tags: {eq: "digitals"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          Photoshoot {
            photos {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
